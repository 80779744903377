<style lang="less" scoped>
@import url('../../assets/css/public.less');
nav {
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    background: #fff;
    .navbar {
        width: 75vw;
        min-width: 1366px;
        margin:0 auto;
        .safeblock {
            width:56.25vw;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .logo {
                width:auto;
                height: 30px;
                display: block;
            }
            > ul {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                // margin-left: 192px;
                li {
                    width: max-content;
                    height: 60px;
                    position: relative;
                    cursor: pointer;
                    margin:0 20px;
                    > a {
                        color: #333;
                        font-size: 14px;
                        font-weight: bold;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    &:hover a {
                        color: #77BE50;
                    }
                    .childrenPage {
                        width: 220px;
                        position: absolute;
                        top: 60px;
                        left: -20px;
                        z-index: 99;
                        padding:0 20px;
                        background: #fff;
                        // display: none;
                        .lv2page {
                            width: 100%;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            border-bottom: 1px solid #eee;
                            a {
                                width: max-content;
                                // height: 100%;
                                display: block;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                // font-size: 16px;
                                color: #333;
                                font-size: 12px;
                                line-height: 1;
                                border-bottom: 1px solid transparent;
                            }
                            // &:hover {
                            //     background: #ddd;
                            // }
                            &:hover a {
                                border-color: #000;
                            }
                        }
                    }
                    &:hover .childrenPage {
                        // display: block;
                    }
                }
            }
            .store {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                img{
                    width:27px;
                    margin-right: 10px;
                }
            }
        }
    }
    .pcnav {
        width: 100%;
        padding: 0 0 40px;
        position: absolute;
        top: 60px;
        left: 0;
        z-index: 99;
        background: #fff;
        .safeblock {
            display: flex;
            .prodRight {
                flex: 1;
                padding-left: 65px;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-wrap: wrap;
                .proItem {
                    width: 260px;
                    height: 280px;
                    margin-right: 20px;
                    margin-bottom: 30px;
                    line-height: 1;
                    cursor: pointer;
                    &:nth-child(4n) {
                        margin-right: 0;
                    }
                    .proType {
                        font-size: 18px;
                        font-weight: 500;
                        color: #bbb;
                        margin-bottom: 20px;
                        &.hidden {
                            opacity: 0;
                        }
                    }
                    // &:hover .proBlock {
                    //     background: #f3f3f3;
                    // }
                    &:hover .proName{
                        color:#77BE50;
                    }
                    .proBlock {
                        width: 100%;
                        height: 240px;
                        padding: 40px 30px;
                        background: transparent;
                        border-radius: 10px;
                        .proImg {
                            width: 100%;
                            position: relative;
                            img {
                                width: 100%;
                            }
                        }
                        .proName {
                            text-align: center;
                            font-size: 16px;
                            font-weight: bold;
                            margin-bottom: 10px;
                            font-family: 'proEn';
                            // text-transform: uppercase;
                        }
                        .proDesc {
                            text-align: center;
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
</style>
<template>
    <nav>
        <div class="navbar">
            <div class="safeblock">
                <a href="/">
                    <img
                        src="@/assets/img/index/logo.png"
                        alt="logo"
                        class="logo"
                    />
                </a>
                <ul>
                    <template v-for="(item, index) in $store.getters.navData">
                        <li
                            v-if="index <= 4"
                            :key="index"
                            @mouseover="showProduct(item, index)"
                            @mouseleave="hideProduct(item, index)"
                        >
                            <a :href="item.url || 'javascript:;'">{{ item.title }}</a>
                            <transition
                                mode="out-in"
                                enter-active-class="animate__animated animate__fadeIn"
                                leave-active-class="animate__animated animate__fadeOut"
                            >
                                <!-- 产品不显示二级菜单 -->
                                <div
                                    class="childrenPage"
                                    v-if="
                                        item.children &&
                                        index === hoverIndex &&
                                        item.title != 'Product'
                                    "
                                >
                                    <div
                                        class="lv2page"
                                        v-for="(p2, i) in item.children"
                                        :key="i"
                                    >
                                        <a :href="p2.url">{{ p2.title }}</a>
                                    </div>
                                </div>
                            </transition>
                        </li>
                    </template>
                </ul>
                <div class="store">
                    <img :src="require('@/assets/img/index/Philippines.jpg')" alt="">
                    <p>Philippines</p>
                </div>
            </div>
        </div>
        <transition
            mode="out-in"
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
        >
            <div :class="['pcnav']" v-show="showNav" @mouseover="showProductContent" @mouseleave="hideProductContent">
                <div class="safeblock">
                    <!-- <div class="filtLeft">
                        <ul>
                            <li :class="[productIndex === index ? 'active' : '',]"
                                v-for="(item, index) in serise"
                                :key="index"
                                @mouseover="clickLeft(item, index)">
                                {{ item.title }}
                            </li>
                        </ul>
                        <a class="compare" href="/compare">产品对比</a>
                    </div> -->
                    <div class="prodRight">
                        <a class="proItem" :href="item.url"
                            v-for="(item, i) in serise"
                            :key="i">
                            <div class="proBlock">
                                <div class="proImg">
                                    <img :src="item.img" alt="" />
                                </div>
                                <div class="proName">{{ item.title }}</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </transition>
    </nav>
</template>
<script>
export default {
    data() {
        return {
            productIndex: 0,
            showNav: false,
            hoverIndex: "",
            navData: [],
            serise: [],
            seo: [],
        };
    },
    mounted() {
        this.serise = this.$store.getters.navData[0].children
    },
    methods: {
        showProduct(item, index) {
            if (item.title == "Product") {
                this.showNav = true;
            }else{
                this.showNav = false;
            }
            this.hoverIndex = index;
        },
        hideProduct(item, index){
            this.hoverIndex = '';
        },
        showProductContent(){
            this.showNav = true;
            this.hoverIndex = 0;
        },
        hideProductContent() {
            this.showNav = false;
        },
    },
};
</script>