<template>
  <div id="app">
    <pcNav v-if="os == 'pc'"></pcNav>
    <mbNav v-else></mbNav>
    <div v-if="os == 'mb'" class="mbPaddingTop"></div>
    <div v-else class="pcPaddingTop"></div>
    <router-view></router-view>
    <pcFoot v-if="os == 'pc'"></pcFoot>
    <mbFoot v-else></mbFoot>
  </div>
</template>

<script>

var os = function (){
	let device = '';
	if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))){
		device = 'mb';
	}else{
		device = 'pc';
	}
	return device;
}();

import pcNav from './components/pc/pcnav'
import pcFoot from './components/pc/pcfoot'
import mbNav from './components/mb/mbnav'
import mbFoot from './components/mb/mbfoot'

export default {
    components:{
      pcNav,
      pcFoot,
      mbNav,
      mbFoot,
    },
    name: 'App',
    data(){
      return {
        os:os,
      }
    },
}
</script>

<style>
.mbPaddingTop{
  height:1.2rem;
}
.pcPaddingTop{
  height:60px;
}
</style>
