import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        navData:[
            {
                title:'Product',
                url:'',
                type:'img',
                children:[
                    {
                        url:'/StareerMotor',
                        img:require('@/assets/img/index/stareer2lit.jpg'),
                        title:'Stareer Motor',
                    },
                    {
                        url:'/Magical3Lit',
                        img:require('@/assets/img/index/magic3lit.jpg'),
                        title:'Magical 3 Lit',
                    },
                    {
                        url:'/Ledo3Lit',
                        img:require('@/assets/img/index/Ledo3Lit.jpg'),
                        title:'Ledo 3 Lit',
                    },
                ]
            },
            {
                title:'Sevice For Warranty',
                url:'',
                type:'text',
                children:[
                    {
                        title:'Promising Service',
                        url:'/PromisingService',
                    },
                    {
                        title:'Star Service',
                        url:'/StarService',
                    },
                    {
                        title:'Warranty',
                        url:'/Warranty',
                    },
                    {
                        title:'Maintenance service',
                        url:'/Maintenanceservice',
                    },
                    {
                        title:'FAQs',
                        url:'/FAQs',
                    },
                ],
            },
            {
                title:'Experience Store inquiry',
                url:'/OurStores',
                desc:'Our Stores',
                type:'text',
                children:false,
            },
            {
                title:'About Us',
                url:'/OFEROPublicInterest',
                desc:'OFERO Public Interest',
                type:'text',
                children:[
                    {
                        title:'OFERO Public Interest',
                        url:'/OFEROPublicInterest',
                    },
                    {
                        title:'Contact Us',
                        url:'/ContactUs',
                    },
                ],
            },
        ],
        userInfo:{
        },
        footerData:[
            {
                title:'设计测验系统',
                children:[
                    {
                        title:'设计测验系统-专业版',
                        url:'/',
                    },
                    {
                        title:'设计测验系统-学生版',
                        url:'/',
                    },
                ]
            },
        ],
    },
    mutations:{
        navData(state,data){
            state.navData = data;
        },
        userInfo(state,data){
            state.userInfo = data;
        },
        footerData(state,data){
            state.footerData = data;
        },
    },
    getters:{
        navData(state){
            return state.navData
        },
        userInfo(state){
            return state.userInfo
        },
        footerData(state){
            return state.footerData
        },
    },
    actions:{
    }
})

export default store