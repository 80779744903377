<style lang="less" scoped>
nav{
    width:100%;
    height:1.2rem;
    // background: linear-gradient(54deg, rgba(116, 189, 82, 1), rgba(178, 209, 46, 1) 100%);
    background:#fff;
    position: fixed;
    top:0;
    left: 0;
    z-index: 9999;
    .navBar{
        width:100%;
        height:100%;
        padding:0 .3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btnMenu{
            // width:1.3rem;
            flex:1;
            display: flex;
            img{
                width:.44rem;
                height:.44rem;
                display: block;
            }
        }
        a.logo{
            display: block;
            img{
                width:1.84rem;
                display: block;
            }
        }
        .goStore{
            // width:1.3rem;
            flex:1;
            display: flex;
            justify-content: flex-end;
        }
    }
    .menu{
        width:100%;
        height:calc(100dvh - 1.2rem);
        background:#333;
        position: fixed;
        top:1.2rem;
        left: 0;
        z-index: 9999;
        display: flex;
        overflow-y: auto;
        padding-bottom: .3rem;
        ul{
            width:100%;
            height:max-content;
        }
        .lv1{
            width:100%;
            min-height: 1rem;
            padding:0 .3rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &.bgWhite{
                background: #fff;
                >.title .txt{
                    color:#333;
                }
            }
            >.title{
                width:100%;
                height:1rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: .01rem solid #fff;
                .txt{
                    font-weight: bold;
                    font-size: .32rem;
                    color:#fff;
                }
                .btn{
                    width:.24rem;
                    height:.24rem;
                    position: relative;
                    img{
                        width:100%;
                        position: absolute;
                        top: 50%;
                        right:0;
                        z-index: 1;
                        transition: all .5s;
                        transform-origin: 50% 50%;
                        transform: translateY(-50%) rotate(0deg);
                    }
                    &.active img+img{
                        transform: translateY(-50%) rotate(90deg);
                    }
                }
            }
            .lv2{
                width:100%;
                height: 0;
                overflow: hidden;
                background:#333;
                transition: height .5s;
                transform:translateY(.2rem);
                &.Sevice{
                }
                .link{
                    width:100%;
                    height:.5rem;
                    padding-left: .3rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    a{
                        font-size: .24rem;
                        color:#cfcfcf;
                        line-height: 1;
                        text-decoration: none;
                    }
                }
            }
            .products{
                width:100%;
                background:#fff;
                display: flex;
                flex-wrap: wrap;
                .item{
                    width:3.35rem;
                    margin-bottom: .5rem;
                    display: block;
                    text-decoration: none;
                    .img{
                        width:100%;
                        height:2.5rem;
                        overflow: hidden;
                        margin-bottom: .2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            width:100%;
                            height:auto;
                            display: block;
                        }
                    }
                    .title{
                        font-size: .24rem;
                        color:#333;
                        text-align: center;
                    }
                }
            }
        }
    }
}
</style>
<template>
    <nav>
        <div class="navBar">
            <div class="btnMenu">
                <img v-if="!menuState" src="@/assets/img/index/nav.png" @click="menuState = !menuState" alt="">
                <img v-if="menuState" src="@/assets/img/index/close.png" @click="menuState = !menuState" alt="">
            </div>
            <a href="/" class="logo"><img src="@/assets/img/index/logo.png" alt=""></a>
            <div class="goStore">
            </div>
        </div>
        <div class="menu" v-if="menuState">
            <ul>
                <li :class="['lv1',item.title=='Product'?'bgWhite':'']" v-for="(item,index) in $store.getters.navData" :key="index">
                    <div class="title" @click="clickTitle(item,index,$event)" :data-h="item.children.length*.5">
                        <div class="txt">{{ item.title }}</div>
                        <div class="btn active">
                            <img src="@/assets/img/index/open.png" alt="">
                            <img src="@/assets/img/index/open.png" alt="">
                        </div>
                    </div>
                    <div class="products" v-if="item.title == 'Product'">
                        <a class="item" :href="prod.url" v-for="(prod,i) in serise" :key="i">
                            <div class="img">
                                <img :src="prod.img" alt="">
                            </div>
                            <div class="title">{{ prod.title }}</div>
                        </a>
                    </div>
                    <div class="lv2" v-else :h="item.children.length*.5" style="height:0">
                        <template v-for="(link,idx) in item.children">
                            <div class="link" :key="idx">
                                <a :href="link.url" class="item">{{ link.title }}</a>
                            </div>
                        </template>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>
<script>
export default {
    data(){
        return {
            btnActive:'',
            menuState:false,
            navData: [],
        }
    },
    mounted(){
        this.serise = this.$store.getters.navData[0].children
    },
    methods:{
        sliceWord(word){
            return word.slice(1);
        },
        clickTitle(item,index,$event){
            this.btnActive = index;
            let h = $event.currentTarget.dataset.h;
            let lv2h = $event.currentTarget.nextElementSibling.dataset.h;
            if(Number(lv2h)){
                $event.currentTarget.nextElementSibling.style.height = 0;
                $event.currentTarget.nextElementSibling.dataset.h = 0;
                $event.currentTarget.children[1].className = 'btn active';
            }else{
                $event.currentTarget.nextElementSibling.style.height = h + 'rem';
                $event.currentTarget.nextElementSibling.dataset.h = h;
                $event.currentTarget.children[1].className = 'btn';
            }
        },
    },
}
</script>