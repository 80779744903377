import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)
var os = function (){
	let device = '';
	if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))){
		device = 'mb';
	}else{
		device = 'pc';
	}
	return device;
}();
const index = ()=>import(`@/components/${os}/index`);
const StareerMotor = ()=>import(`@/components/${os}/StareerMotor`);
const Magical3Lit = ()=>import(`@/components/${os}/Magical3Lit`);
const Ledo3Lit = ()=>import(`@/components/${os}/Ledo3Lit`);

const PromisingService = ()=>import(`@/components/${os}/PromisingService`);
const StarService = ()=>import(`@/components/${os}/StarService`);
const  Warranty = ()=>import(`@/components/${os}/Warranty`);
const Maintenanceservice = ()=>import(`@/components/${os}/Maintenanceservice`);
const FAQs = ()=>import(`@/components/${os}/FAQs`);
const OurStores = ()=>import(`@/components/${os}/OurStores`);
const OFEROPublicInterest = ()=>import(`@/components/${os}/OFEROPublicInterest`);
const ContactUs = ()=>import(`@/components/${os}/ContactUs`);

let pcRouters = [
	{
		path: '/',
		name: 'index',
		component: index,
	},
	{
		path: '/StareerMotor',
		name: 'StareerMotor',
		component: StareerMotor,
	},
	{
		path: '/Magical3Lit',
		name: 'Magical3Lit',
		component: Magical3Lit,
	},
	{
		path: '/Ledo3Lit',
		name: 'Ledo3Lit',
		component: Ledo3Lit,
	},
	{
		path: '/PromisingService',
		name: 'PromisingService',
		component: PromisingService,
	},
	{
		path: '/StarService',
		name: 'StarService',
		component: StarService,
	},
	{
		path: '/Warranty',
		name: ' Warranty',
		component:  Warranty,
	},
	{
		path: '/Maintenanceservice',
		name: 'Maintenanceservice',
		component: Maintenanceservice,
	},
	{
		path: '/FAQs',
		name: 'FAQs',
		component: FAQs,
	},
	{
		path: '/OurStores',
		name: 'OurStores',
		component: OurStores,
	},
	{
		path: '/OFEROPublicInterest',
		name: 'OFEROPublicInterest',
		component: OFEROPublicInterest,
	},
	{
		path: '/ContactUs',
		name: 'ContactUs',
		component: ContactUs,
	},
	{
		path: '*',
		redirect:'/',
	},
];
let phoneRouters = [
	{
		path: '/',
		name: 'index',
		component: index,
	},
	{
		path: '/StareerMotor',
		name: 'StareerMotor',
		component: StareerMotor,
	},
	{
		path: '/Magical3Lit',
		name: 'Magical3Lit',
		component: Magical3Lit,
	},
	{
		path: '/Ledo3Lit',
		name: 'Ledo3Lit',
		component: Ledo3Lit,
	},
	{
		path: '/PromisingService',
		name: 'PromisingService',
		component: PromisingService,
	},
	{
		path: '/StarService',
		name: 'StarService',
		component: StarService,
	},
	{
		path: '/Warranty',
		name: ' Warranty',
		component:  Warranty,
	},
	{
		path: '/Maintenanceservice',
		name: 'Maintenanceservice',
		component: Maintenanceservice,
	},
	{
		path: '/FAQs',
		name: 'FAQs',
		component: FAQs,
	},
	{
		path: '/OurStores',
		name: 'OurStores',
		component: OurStores,
	},
	{
		path: '/OFEROPublicInterest',
		name: 'OFEROPublicInterest',
		component: OFEROPublicInterest,
	},
	{
		path: '/ContactUs',
		name: 'ContactUs',
		component: ContactUs,
	},
	{
		path: '*',
		redirect:'/',
	},
];
let routers = os=='pc'?pcRouters:phoneRouters;

const RT = new Router({
	mode:'history',
  	routes: routers,
})
RT.beforeEach((to,from,next)=>{
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
	next();
})
export default RT