<style lang="less" scoped>
footer{
    width:100%;
    padding:.6rem .4rem;
    background:#333;
    
    .menu{
        width:100%;
        display: flex;
        flex-direction: column;
        >img{
            width:max-content;
            height:.8rem;
            display: block;
            margin-left: .3rem;
            margin-bottom: .4rem;
        }
        ul{
            width:100%;
        }
        .lv1{
            width:100%;
            height:max-content;
            padding:0 .3rem;
            margin-bottom: .3rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            >.title{
                width:100%;
                height:.5rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                // border-bottom: .01rem solid #fff;
                .txt{
                    font-weight: bold;
                    font-size: .32rem;
                    color:#fff;
                }
                .btn{
                    width:.24rem;
                    height:.24rem;
                    position: relative;
                    img{
                        width:100%;
                        position: absolute;
                        top: 50%;
                        right:0;
                        z-index: 1;
                        transition: all .5s;
                        transform-origin: 50% 50%;
                        transform: translateY(-50%) rotate(0deg);
                    }
                    &.active img+img{
                        transform: translateY(-50%) rotate(90deg);
                    }
                }
            }
            .lv2{
                width:100%;
                height:auto;
                overflow: hidden;
                background:#333;
                transition: height .5s;
                &.service{
                    height:0;
                }
                .link{
                    width:100%;
                    height:.5rem;
                    padding-left: .3rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    a{
                        font-size: .24rem;
                        color:#cfcfcf;
                        line-height: 1;
                        text-decoration: none;
                    }
                }
            }
            .products{
                width:100%;
                .item{
                    width:3.35rem;
                    margin-bottom: .5rem;
                    .title{
                        font-size: .24rem;
                        color: #fff;
                        text-align: center;
                    }
                }
            }
        }
    }
}
</style>
<template>
    <footer>
        <div class="menu">
            <img src="@/assets/img/index/foot.png" alt="">
            <ul>
                <li :class="['lv1',item.title=='Product'?'bgWhite':'']" v-for="(item,index) in links" :key="index">
                    <div class="title" @click="clickTitle(item,index,$event)" :data-h="item.children.length*.5">
                        <div class="txt">{{ item.title }}</div>
                        <div class="btn active">
                            <img src="@/assets/img/index/open.png" alt="">
                            <img src="@/assets/img/index/open.png" alt="">
                        </div>
                    </div>
                    <!-- <div class="lv2" :style="`height:${btnActive === index ? item.children.length*.5:0}`"> -->
                        <div class="lv2" :style="`height:0`">
                        <template v-for="(link,idx) in item.children">
                            <div class="link" :key="idx">
                                <a :href="link.url" class="item">{{ link.title }}</a>
                            </div>
                        </template>
                    </div>
                </li>
            </ul>
        </div>
    </footer>
</template>
<script>
export default {
    data() {
        return {
            links:[],
            btnActive:0,
            openService:false,
            openProduct:true,
            rightShow:'产品',
            navData: [],
            showStore:false,
        }
    },
    mounted(){
        this.initLinks();
    },
    methods:{
        initLinks(){
            let arr = this.$store.getters.navData;
            // arr = JSON.parse(JSON.stringify(arr));
            for(let i=0;i<arr.length;i++){
                if(arr[i].children === false){
                    arr[i].children = [
                        {
                            title:arr[i].desc,
                            url:arr[i].url,
                        }
                    ]
                }
            }
            this.links = arr;
        },
        clickPro(){
            this.openPro = !this.openPro;
        },
        showPic(val){
            this[val] = !this[val];
        },
        toTop() {
            let vm = this;
            cancelAnimationFrame(vm.timer);
            vm.timer = requestAnimationFrame(function fn() {
                var oTop = document.body.scrollTop || document.documentElement.scrollTop;
                if (oTop > 0) {
                    document.body.scrollTop = document.documentElement.scrollTop = oTop - 80;
                    vm.timer = requestAnimationFrame(fn);
                } else {
                    cancelAnimationFrame(vm.timer);
                }
            });
        },
        // clickTitle(item){
        //     switch(item.title){
        //         case 'Product': this.openProduct = !this.openProduct;break;
        //         case 'Sevice For Warranty': this.openService = !this.openService;break;
        //     }
        // },
        clickTitle(item,index,$event){
            let h = $event.currentTarget.dataset.h;
            let lv2h = $event.currentTarget.nextElementSibling.dataset.h;
            if(Number(lv2h)){
                $event.currentTarget.nextElementSibling.style.height = 0;
                $event.currentTarget.nextElementSibling.dataset.h = 0;
                $event.currentTarget.children[1].className = 'btn active';
            }else{
                $event.currentTarget.nextElementSibling.style.height = h + 'rem';
                $event.currentTarget.nextElementSibling.dataset.h = h;
                $event.currentTarget.children[1].className = 'btn';
            }
        },
    }
}
</script>