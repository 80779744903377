<style lang="less" scoped>
a{
  text-decoration: none;
}
.box_8 {
  background-color: rgba(0, 0, 0, 1);
  height: 344px;
  margin: -1px auto 0;
  width: 100%;
  justify-content: flex-center;
}

.section_1 {
  width: 910px;
  height: 98px;
  margin: 100px auto 0;
}

.image_13 {
  width: 66px;
  height: 40px;
}

.group_3 {
  width: 61px;
  height: 98px;
  margin-left: 116px;
}

.text_19 {
  width: 61px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Helvetica Neue-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}

.text-group_3 {
  width: 53px;
  height: 61px;
  margin-top: 20px;
}

.text_20 {
  width: 53px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
}

.text_21 {
  width: 31px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
  margin-top: 8px;
}

.text_22 {
  width: 31px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
  margin-top: 8px;
}

.text-wrapper_10 {
  width: 153px;
  height: 98px;
  margin-left: 80px;
}

.text_23 {
  width: 128px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Helvetica Neue-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}

.text_24 {
  width: 101px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
  margin-top: 20px;
}

.text_25 {
  width: 68px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
  margin-top: 8px;
}

.text_26 {
  width: 153px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
  margin-top: 8px;
}

.group_4 {
  width: 164px;
  height: 75px;
  margin-left: 74px;
}

.text_27 {
  width: 164px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Helvetica Neue-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}

.text-group_4 {
  width: 60px;
  height: 38px;
  margin-top: 20px;
}

.text_28 {
  width: 55px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
}

.text_29 {
  width: 60px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
  margin-top: 8px;
}

.text-wrapper_11 {
  width: 116px;
  height: 98px;
  margin-left: 80px;
}

.text_30 {
  width: 62px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-family: Helvetica Neue-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
}

.text_31 {
  width: 107px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
  margin-top: 20px;
}

.text_32 {
  width: 116px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
  margin-top: 8px;
}

.text_33 {
  width: 98px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
  margin-top: 8px;
}

.text-wrapper_12 {
  width: 596px;
  height: 15px;
  margin: 8px 0 0 563px;
}

.text_34 {
  width: 117px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
}

.text_35 {
  width: 125px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
}

.text-wrapper_13 {
  width: 1117px;
  height: 15px;
  margin: 8px 0 100px 563px;
}

.text_36 {
  width: 30px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
}

.text_37 {
  width: 63px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
  margin-left: 441px;
}

.text_38 {
  width: 173px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(187, 187, 187, 0.99);
  font-size: 12px;
  font-family: Helvetica Neue-Medium;
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  line-height: 12px;
  margin-left: 410px;
}

</style>
<template>
    <div class="box_8 flex-col footer">
      <div class="section_1 flex-row">
        <img
          class="image_13"
          referrerpolicy="no-referrer"
          src="@/assets/img/index/foot.png"
        />
        <div class="group_3 flex-col justify-between">
          <span class="text_19">Products</span>
          <div class="text-group_3 flex-col justify-between">
            <a href="/StareerMotor" class="text_20">Stareer Motor</a>
            <a href="/Magical3Lit" class="text_21">Magical 3 Lit</a>
            <a href="/Ledo3Lit" class="text_22">Ledo 3 Lit</a>
          </div>
        </div>
        <div class="text-wrapper_10 flex-col">
          <span class="text_23">Sevice For Warranty</span>
          <a href="/PromisingService" class="text_24">Promising Service</a>
          <a href="/StarService" class="text_25">Star Service</a>
          <a href="/Warranty" class="text_26">Warranty</a>
          <a href="/Maintenanceservice" class="text_26">Maintenance service</a>
          <a href="/FAQs" class="text_26">FAQs</a>
        </div>
        <div class="group_4 flex-col justify-between">
          <span class="text_27">Experience&nbsp;Store&nbsp;Inquiry</span>
          <div class="text-group_4 flex-col justify-between">
            <a href="/OurStores" class="text_28">Our Stores</a>
          </div>
        </div>
        <div class="text-wrapper_11 flex-col">
          <span class="text_30">About&nbsp;Us</span>
          <a href="/OFEROPublicInterest" class="text_31">OFERO Public Interest</a>
          <a href="/ContactUs" class="text_25">Contact Us</a>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
        }
    },
}
</script>